@import url(../../stylesheets/tools/media-queries.css);

.logoTextBanner {
  display: flex;
  align-items: center;
  @apply p-3;
  @apply bg-yellow-400;
  @apply text-black;

  + .logoTextBanner {
    @apply mt-4;
  }
}

.logo {
  flex-shrink: 0;
  max-height: 2.25rem;
  max-width: 25%;
}

.content {
  @apply ml-4;

  @mixin desktop {
    @apply ml-5;
  }

  /* content might be rendered in backend from markdown in wrapped in a paragraph p tag */
  p {
    display: inline;
  }
}
