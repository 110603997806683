@import url(../../stylesheets/tools/media-queries.css);

.headline {
  margin-top: 0;
  @apply mb-6;
  @apply text-2xl;
  text-align: center;

  @mixin desktop {
    @apply text-3xl;
    text-align: left;
  }
}

.teasers {
  display: flex;
  flex-direction: column;

  @mixin desktop {
    flex-direction: row;
    @apply gap-4;
  }
}

.teaser {
  display: flex;
  align-items: flex-start;
  @apply px-4;
  @apply bg-gray-100;

  @mixin mobile {
    + .teaser {
      @apply mt-8;
    }
  }

  @mixin desktop {
    @apply py-4;
  }
}

.teaserContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @apply ml-4;
}
