@import url(../../stylesheets/tools/media-queries.css);

.modalLink {
  display: inline-block;
}

.needRow {
  position: relative;
}

.needData:last-of-type {
  text-align: right;
}
