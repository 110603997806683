@import url(../../stylesheets/tools/media-queries.css);

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  @apply gap-4;
}

.headline {
  @apply mb-6;
  @apply text-2xl;
  text-align: center;

  @mixin desktop {
    @apply text-3xl;
  }
}
