@import url(../../stylesheets/tools/media-queries.css);

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @apply mb-6;
}

.headline {
  @apply text-2xl;
  margin: 0;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li + li {
    @apply mt-2;
  }
}

.pagination {
  list-style: none;
  margin: 0;
}
