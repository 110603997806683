@import url(../stylesheets/tools/media-queries.css);

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.buttonGroup {
  @apply mt-3;

  :global(> * + .btn-block) {
    @apply mt-4;
  }

  @mixin mobile {
    display: none;
  }
}

[id] {
  scroll-margin-top: 5rem;
}

.adminBox {
  @apply mt-4;
  @apply mb-10;

  @mixin desktop {
    @apply my-6;
  }
}

.taxNotice {
  @apply mt-4;
  @apply mb-3;

  @mixin desktop {
    @apply mt-6;
  }
}
