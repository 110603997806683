@import url(../../stylesheets/tools/media-queries.css);

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @apply gap-1;
}

.totalAmount {
  display: flex;
  flex-direction: column;
}

.progressBar {
  @apply mb-4;
  @apply mt-2;
}

.progressText {
  display: flex;
  justify-content: space-between;
}

.label {
  @apply ml-2;
}

.number {
  @apply font-semibold;
  @apply text-purple-600;
}

.largeNumber {
  @apply text-3xl;
  @apply mb-1;
}

.link {
  text-align: right;
  @apply mt-105;
}
