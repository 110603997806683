@import url(../../stylesheets/tools/media-queries.css);

.headline {
  @apply mb-6;
  @apply text-2xl;
  text-align: center;

  @mixin desktop {
    @apply text-3xl;
    text-align: left;
  }
}

.gridContainer {
  display: grid;
  @apply gap-6;
  @apply grid-cols-1;

  @mixin desktop {
    @apply grid-cols-3;
  }
}

.gridItem {
  @mixin desktop {
    @apply col-span-2;
  }
}

.gridItemSidebar {
  @mixin desktop {
    @apply col-span-1;

    &:first-child {
      @apply order-2;
    }
  }
}
