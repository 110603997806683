@import url(../../stylesheets/tools/media-queries.css);

.container {
  text-align: center;
}

.headline {
  margin-top: 0;
  @apply mb-6;
  @apply text-2xl;
  @apply text-black;

  @mixin desktop {
    @apply text-3xl;
  }
}

.shareButtons {
  display: flex;
  justify-content: center;
  @apply gap-4;
}
