@import url(../../stylesheets/tools/media-queries.css);

.container {
  @apply text-2xl;

  @mixin desktop {
    @apply text-4xl;
    text-align: center;
  }
}
