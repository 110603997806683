.container {
  > * + * {
    @apply mt-4;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @apply text-white;
  @apply bg-yellow-400;
  @apply p-4;
}

.logo {
  max-height: 45px;
  max-width: 45%;

  + .logo {
    flex-grow: 0;
    flex-shrink: 1;
    margin-left: auto;

    /* we need to make an exeption here, because the
    sparkassen logos contain their own transparent
    padding and we cannot easily change that */
    max-height: 80px;
    @apply -mt-4;
    @apply -mb-4;
    @apply -mr-4;
  }
}

.buttonWrapper {
  display: flex;
}

.singleButtonWrapper {
  justify-content: flex-end;
}

.multipleButtonsWrapper {
  justify-content: space-between;
  gap: 1rem;
}

.furtherInfo {
  @apply my-6;
}
