@import url(../../stylesheets/tools/media-queries.css);

.container {
  display: flex;
  align-items: flex-start;

  @mixin desktop {
    flex-direction: column;
  }
}

.picture {
  height: 80px;
  border-radius: 9999px;
  border: 1px solid;
  @apply border-gray-200;
}

.text {
  @apply ml-2;

  @mixin desktop {
    margin-left: 0;
    @apply mt-2;
  }
}

.link {
  @apply mt-2;
}
