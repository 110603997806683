.container {
  display: flex;
  align-items: center;
  @apply bg-wirwunder;
  @apply bg-opacity-20;
  @apply px-4;
  @apply py-2;
}

.logoContainer {
  @apply w-14;
  @apply h-14;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
  @apply bg-wirwunder;
}

.logo {
  height: 50%;
}

.text {
  @apply ml-4;
}
