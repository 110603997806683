@import url(../../stylesheets/tools/media-queries.css);

.container {
  position: relative;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @apply mb-6;

  @mixin desktop {
    justify-content: space-between;
  }
}

.headline {
  @apply text-2xl;
  text-align: center;
  margin: 0;

  @mixin desktop {
    @apply text-3xl;
    text-align: left;
  }
}

.singleNeedContainer {
  @mixin desktop {
    width: 33.3333%;
    @apply pr-3;
  }
}

.pagination {
  @apply pl-4;

  @mixin mobile {
    display: none;
  }
}

.slide {
  user-select: none;
}

.completedNeedsButton {
  @apply mt-7;

  @mixin mobile {
    text-align: center;
  }
}

.swiperContainer :global(.swiper-slide) {
  visibility: hidden;
  transition: visibility 0.3s;
}

.swiperContainer :global(.swiper-slide.swiper-slide-visible) {
  visibility: visible !important;
}
