.container {
  overflow: hidden;
  position: relative;

  + button {
    @apply mt-3;
  }
}

.fader {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, #ffffff00, #ffffffff 90%);
  @apply z-10;
}
