@import url(../../stylesheets/tools/media-queries.css);

.section {
  @apply mt-6;

  @mixin desktop {
    @apply mt-11;
  }
}

.largeSpacingMobile {
  @mixin mobile {
    @apply mt-10;
  }
}

.hasBackground {
  @apply py-8;

  @mixin desktop {
    @apply py-11;
  }

  + .hasBackground {
    margin-top: 0;
  }
}

.gentleBackground {
  @apply bg-gray-100;
}

.foggyBackground {
  @apply bg-gray-200;
}

.prominentBackground {
  @apply bg-green-500;
}

.backgroundMobileOnly {
  @mixin desktop {
    @apply py-0;
    background-color: transparent;
  }
}
