.formGroup {
  @apply mb-4;
}

.formActions {
  text-align: center;
}

.disclaimer {
  @apply text-muted;
  @apply mt-4;
}
