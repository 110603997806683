@import url(../../stylesheets/tools/media-queries.css);

.header {
  @apply mb-6;
}

.headline {
  @apply text-2xl;
  text-align: center;
  margin: 0;

  @mixin desktop {
    @apply text-3xl;
    text-align: left;
  }
}

.singleNewsContainer {
  display: flex;

  @mixin desktop {
    width: 33.3333%;
    @apply pr-4;
  }
}

.slide {
  user-select: none;
}

.showAllLink {
  @apply mt-7;

  @mixin mobile {
    text-align: center;
  }
}

.swiperContainer :global(.swiper-slide) {
  visibility: hidden;
  transition: visibility 0.3s;
}

.swiperContainer :global(.swiper-slide.swiper-slide-visible) {
  visibility: visible !important;
}
