/* stylelint-disable */
:root {
  --root-line-ratio: 1.5;
  --root-line-absolute: calc(1rem * var(--root-line-ratio));
  --space-xxs: calc(var(--root-line-absolute) * 0.25);
  --space-xs: calc(var(--root-line-absolute) * 0.5);
  --space-sm: calc(var(--root-line-absolute) * 0.75);
  --space-md: calc(var(--root-line-absolute) * 1);
  --space-lg: calc(var(--root-line-absolute) * 1.25);
  --space-xl: calc(var(--root-line-absolute) * 1.5);
  --space-xxl: calc(var(--root-line-absolute) * 1.75);
  --space-3xl: calc(var(--root-line-absolute) * 2);
}

:root {
  --space: var(--space-md);
  --container-size: 960px;
  --border-thin: 0.125rem;
  --color-light: var(--light);
  --color-dark: var(--dark);
}