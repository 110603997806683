.modalContent > * + * {
  @apply mt-4;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  > a + a {
    @apply mt-3;
  }
}
