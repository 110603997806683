@import url(../../stylesheets/tools/media-queries.css);

.container {
  @apply text-lg;
}

.headline {
  display: flex;
  justify-content: center;
  @apply text-3xl;
  margin-top: 0;
  @apply mb-4;

  @mixin desktop {
    @apply mb-8;
  }
}

.betterplaceLogo {
  @apply ml-2;
}

.text {
  @apply mb-4;

  @mixin desktop {
    @apply mb-8;
  }
}

.facts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;

  @mixin desktop {
    flex-direction: row;
  }
}

.fact {
  display: flex;
  align-items: center;
  @apply mb-4;

  @mixin desktop {
    margin-bottom: 0;
  }

  @mixin desktop {
    flex-direction: column;
  }
}

.factNumber {
  flex: 0 0 33.33%;
  @apply text-purple-600;
  @apply text-xl;

  @mixin desktop {
    flex-basis: auto;
  }
}
