@import url(../../stylesheets/tools/media-queries.css);

.container {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;

  @mixin desktop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.organisationInfo {
  flex-basis: calc(33.33% - 8px);
}

.shortDescription {
  flex-basis: calc(66.66% - 8px);

  @mixin mobile {
    @apply mt-4;
  }
}
