@import url(../../stylesheets/tools/media-queries.css);

.senderVisualContainer {
  @apply w-14;
  @apply h-14;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @apply text-white;
}

.container {
  display: flex;
  flex-direction: column;
  @apply bg-white;
  @apply px-4;
  @apply py-2;

  @mixin desktop {
    @apply bg-gray-100;
  }

  &:not(.sponsored) {
    .senderVisualContainer {
      @apply rounded-full;
      overflow: hidden;
      background-color: white;
    }

    &:not(.hasImage) {
      &:nth-child(even) {
        .senderVisualContainer {
          @apply bg-teal-700;
        }
      }

      &:nth-child(odd) {
        .senderVisualContainer {
          @apply bg-purple-700;
        }
      }
    }
  }
}

.donationItem {
  display: flex;
  align-items: center;
}

.visualPlaceholderIcon {
  @apply w-6;
  @apply h-6;
}

.senderInfoContainer {
  display: flex;
  flex-direction: column;
  @apply ml-4;
}

.senderName {
  @apply font-bold;
}

.senderTime {
  @apply text-sm;
  @apply text-gray-700;
}

.additionalInfosContainer {
  @apply text-sm;
}

.additionalInfo {
  @apply mt-2;
  white-space: pre-line;
}
