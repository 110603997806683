@import url(../../stylesheets/tools/media-queries.css);

.container {
  position: relative;
  @apply border;
  @apply border-gray-300;
  border-style: solid;
  padding: 20px;
  font-weight: normal;
  color: inherit;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  max-width: 100%;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &.disabled {
    cursor: default;
  }
}

.title {
  @apply font-bold;
  overflow-wrap: break-word;
  width: 100%;
}

.amount {
  @apply text-sm;
  @apply text-purple-600;
  @apply font-bold;
  line-height: 36px;
}

.description {
  flex-grow: 1;
  overflow: hidden;
  margin-bottom: 15px;

  @mixin desktop {
    margin-bottom: 20px;
  }
}
